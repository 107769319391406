<template>
    <body class="bg-img-hero-fixed">
        <header
            id="header"
            class="header header-bg-transparent header-abs-top py-3"
        >
            <div class="header-section">
                <div id="logoAndNav" class="container">
                    <nav class="navbar navbar-left">
                        <a
                            class="navbar-brand"
                            href="../landings/index.html"
                            aria-label="Front"
                        >
                            <img src="@/assets/logo.png" alt="Logo" />
                        </a>
                    </nav>
                </div>
            </div>
        </header>
        <main id="content" role="main">
            <!-- Hero Section -->
            <div class="d-lg-flex">
                <div
                    class="container d-lg-flex align-items-lg-center min-vh-lg-100 space-4"
                >
                    <div class="w-lg-60 w-xl-50">
                        <!-- Title -->
                        <div class="mb-4">
                            <img
                                class="max-w-23rem mb-3"
                                alt="SVG Illustration"
                                src="@/assets/svg/illustrations/error-number-404.svg"
                            />
                            <p class="lead">
                                Oops! Looks like you followed a bad link. <br />
                                If you think this is a problem with us, please
                                <a
                                    href="javascript:;"
                                    @click="$router.push({ name: 'Contact' })"
                                    >tell us.</a
                                >
                            </p>
                        </div>
                        <!-- End Title -->

                        <btn
                            @click="$router.push({ name: 'Home' })"
                            class="transition-3d-hover btn-wide"
                        >
                            Back Home
                        </btn>
                    </div>
                </div>
            </div>
            <!-- End Hero Section -->
        </main>
        <!-- ========== END MAIN ========== -->

        <!-- ========== FOOTER ========== -->
        <footer class="position-absolute right-0 bottom-0 left-0">
            <div class="container">
                <div
                    class="d-flex justify-content-between align-items-center space-1"
                >
                    <!-- Copyright -->
                    <p class="small text-muted mb-0">
                        &copy; 2021 All rights reserved to thriftax.com
                    </p>
                    <!-- End Copyright -->
                </div>
            </div>
        </footer>
    </body>
</template>

<script>
export default {};
</script>

<style scoped>
.bg-img-hero-fixed {
    background-image: url('~@/assets/svg/illustrations/error-404.svg');
}
</style>
